.ant-upload-list-item-name{
    color: black !important;
}
.anticon-paper-clip{
    color: black !important;
    top: 0px;
}
.ant-upload-list-item-container>div:nth-child(2){
    display: none !important;
}
.ant-upload-list-item-action{
    opacity: 1 !important;
}
.ant-upload-list-item-action>span{
    color: red !important;
}











/* //// */
.iamgeBox{
    height: 300px;
    width: 100%;
    /* border: 1px solid black; */
    border-radius: 10px;
    box-shadow:  rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;;
}
.cardHeaderImage{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(116, 114, 114);
    padding: 7px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;; */
}
.cardHeaderImage>h6{
  padding-top: 7px;
  font-weight: bold;
  font-size: 20px;
}
.imageContainerUpload{
    padding: 2px;
    display: inline-block;
    height: 234px;
    width: 100%;
}
.imageContainerUpload>img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}